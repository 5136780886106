import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import { Paper } from "@mui/material";
import React, { useState } from "react";

export const LogoUpload = ({
  imageRef,
  handleImageRef,
  _preview_hidden,
  sx,
}) => {
  const [imageRefError, setImageRefError] = useState(false);

  function handleImageSelection(file) {
    if (file.type === "image/heic" || file.type === "image/tiff") {
      setImageRefError(true);
      return;
    }
    const obj = URL.createObjectURL(file);
    handleImageRef({
      uri: obj,
      name: file.name,
      type: file.type,
    });
  }

  return (
    <div
      style={
        sx ? { ...style.imageContainer, ...sx.container } : style.imageContainer
      }
    >
      <label htmlFor="logo-file-upload">
        <Paper
          id="SetUp"
          className={
            !imageRefError
              ? "custom-file-upload"
              : "custom-file-upload custom-file-upload-error"
          }
        >
          <AddPhotoAlternateIcon fontSize="large" />
          <p className={"uploadImageText"}>Select Event Icon</p>
        </Paper>
      </label>
      {_preview_hidden ? null : imageRef !== null ? (
        <img src={imageRef.uri} style={style.image} alt="preview" />
      ) : (
        <div style={style.image}></div>
      )}
      <input
        id="logo-file-upload"
        type="file"
        onChange={(event) => handleImageSelection(event.target.files[0])}
      />
    </div>
  );
};

const style = {
  imageContainer: {
    display: "flex",
  },
  image: {
    width: "50px",
    height: "50px",
    marginLeft: 25,
    marginTop: 12.5,
  },
};
