import React, { useState, useContext, useEffect } from "react";
import { addCacheByPath, AdminContext } from "../util/context";
import { IconButton } from "@mui/material";
import {
  deleteArt,
  getArt,
  getArtistByID,
  getAuction,
  getImageLink,
  updateArt,
  uploadToAws,
} from "../util/api";
import { useNavigate } from "react-router-dom";
import QrCodeIcon from "@mui/icons-material/QrCode";
import saveSvgAsPng from "save-svg-as-png";
import Dash from "../components/Dash";
import ArtEditorPanel from "../components/dashboard/ArtEditorPanel";
import Warning from "../components/Warning";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingScreen from "../components/LoadingScreen";
import QRCodeSVG from "../components/QRCodeSVG";
import QRCodeSVGColor from "../components/QRCodeSVGColor";

function matchDimensionRegex(str) {
  let regex = /\d+x\d+/;
  return regex.test(str);
}

function computeMutation(newRow, oldRow) {
  if (newRow.title !== oldRow.title && newRow.title !== "") {
    return `Title from '${oldRow.title}' to '${newRow.title}'`;
  }
  if (newRow.description !== oldRow.description && newRow.description !== "") {
    return `Description from '${oldRow.description}' to '${newRow.description}'`;
  }
  if (newRow.medium !== oldRow.medium && newRow.medium !== "") {
    return `Medium from '${oldRow.medium}' to '${newRow.medium}'`;
  }
  if (
    newRow.dimension !== oldRow.dimension &&
    newRow.dimension !== "" &&
    matchDimensionRegex(newRow.dimension)
  ) {
    return `Dimension from '${oldRow.dimension}' to '${newRow.dimension}'`;
  }
  if (
    newRow.min_price !== oldRow.min_price &&
    newRow.min_price !== "" &&
    !isNaN(newRow.min_price) &&
    Number(newRow.min_price) >= 0
  ) {
    return `Min price from '${oldRow.min_price || ""}' to '${
      newRow.min_price || ""
    }'`;
  }
  if (
    newRow.buy_price !== oldRow.buy_price &&
    newRow.buy_price !== "" &&
    !isNaN(newRow.buy_price) &&
    Number(newRow.buy_price) >= -1
  ) {
    return `Buy price from '${oldRow.buy_price || ""}' to '${
      newRow.buy_price || ""
    }'`;
  }
  if (newRow.year !== oldRow.year) {
    return `Year from '${oldRow.year || ""}' to '${newRow.year || ""}'`;
  }
  return null;
}

function mutateRow(newRow) {
  const data = {
    description: newRow.description,
    dimension: newRow.dimension,
    medium: newRow.medium,
    min_price: Number(newRow.min_price),
    title: newRow.title,
    year: Number(newRow.year),
    buy_price: Number(newRow.buy_price),
  };
  return updateArt(newRow.artID, data);
}

/** Dashboard component displays the current selected auction */
export default function ArtEditor() {
  const navigate = useNavigate();
  const [state] = useContext(AdminContext);
  const [auction, setAuction] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, handleLoading] = useState(false);
  const [editMode, setEditMode] = useState([false, {}]);
  const [reload, handleReload] = useState(false);
  const [qrColorMode, setQRColorMode] = useState(false);

  const small_width = 62.5;
  const small_flex = 0.25;
  const med_width = 125;
  const med_flex = 0.5;
  const large_width = 175;
  const large_flex = 0.75;

  const imageOptions = {
    encoderOptions: 1,
  };

  const downloadQR = (id, title) => {
    let svg = document.getElementById(id);
    saveSvgAsPng.saveSvgAsPng(svg, `${title}.png`, imageOptions);
  };

  const [open, setOpen] = useState(false);
  const [deleteData, handleDeleteData] = useState({});

  /** handleClickOpen is called when a user presses the delete button. */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /** handleClose occurs when a piece is about to be deleted. If deleteState
   *  is true, then calls _deleteArt(); otherwise does nothing.
   */
  const handleClose = (deleteState) => {
    setOpen(false);
    if (!deleteState) {
      _deleteArt();
    }
  };

  async function _deleteArt() {
    console.log(deleteData);
    await deleteArt(deleteData.artID, deleteData.image_ref);
    handleReload(true);
  }

  const tools = (params) => {
    const qr_data = params.row;
    return params.row.image_ref !== undefined ? (
      <div>
        <IconButton
          aria-label="edit"
          onClick={() => downloadQR(params.row.id, params.row.title)}
        >
          <QrCodeIcon style={{ color: "white" }} />
        </IconButton>
        <IconButton
          aria-label="edit"
          onClick={() => {
            handleDeleteData(params.row);
            handleClickOpen();
          }}
        >
          <DeleteIcon style={{ color: "white" }} />
        </IconButton>
        {qrColorMode ? (
          <QRCodeSVGColor data={qr_data} />
        ) : (
          <QRCodeSVG data={qr_data} />
        )}
      </div>
    ) : (
      ""
    );
  };

  async function handleImageFile(file, row) {
    if (
      !file.type.startsWith("image/") ||
      file.type === "image/heic" ||
      file.type === "image/tiff"
    ) {
      return;
    }

    const obj = URL.createObjectURL(file);
    const image_ref = {
      uri: obj,
      name: file.name,
      type: file.type,
    };
    const data = {
      description: row.description,
      dimension: row.dimension,
      medium: row.medium,
      min_price: Number(row.min_price),
      title: row.title,
      year: Number(row.year),
      image_ref: image_ref === null ? null : image_ref.name,
      buy_price: Number(row.buy_price),
    };
    const res = await updateArt(row.artID, data);
    if (res === null) {
    } else {
      await uploadToAws(image_ref);
      handleReload(true);
    }
  }

  function handleThumbnailClick(row) {
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = (event) => {
      handleImageFile(event.target.files[0], row);
    };
    input.click();
  }

  const imageThumb = (params) => {
    return (
      <div
        style={{ display: "flex", justifyContent: "center" }}
        onClick={() => handleThumbnailClick(params.row)}
      >
        <img src={params.row.image_ref} width={"60%"} alt={params.row.title} />
      </div>
    );
  };

  const _renderHeader = (title) => {
    return <span style={{ fontWeight: "bold" }}>{title}</span>;
  };

  const columns = [
    {
      field: "image",
      flex: small_flex,
      midWidth: small_width,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => imageThumb(params),
      renderHeader: () => _renderHeader(""),
    },
    {
      field: "title",
      flex: 1,
      midWidth: 250,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Title"),
      editable: true,
    },
    {
      field: "artist_name",
      flex: large_flex,
      midWidth: large_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Artist"),
    },
    {
      field: "medium",
      flex: med_flex,
      midWidth: med_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Medium"),
      editable: true,
    },
    {
      field: "year",
      flex: small_flex,
      midWidth: small_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Year"),
      editable: true,
    },
    {
      field: "description",
      flex: large_flex,
      midWidth: large_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Description"),
      editable: true,
    },
    {
      field: "dimension",
      flex: med_flex,
      midWidth: med_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Dimension"),
      editable: true,
    },
    {
      field: "min_price",
      flex: med_flex,
      midWidth: med_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Min Price"),
      valueFormatter: (params) => {
        return params.value === undefined ? "" : `$${params.value}`;
      },
      editable: true,
    },
    {
      field: "buy_price",
      flex: med_flex,
      midWidth: med_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Buy Price"),
      valueFormatter: (params) => {
        return params.value === undefined || params.value === "-1"
          ? ""
          : `$${params.value}`;
      },
      editable: true,
    },
    {
      field: "tools",
      flex: small_flex,
      midWidth: small_width,
      renderCell: (params) => tools(params),
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params,
      renderHeader: () => _renderHeader(""),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      handleLoading(true);

      // get auction from Event ID
      const auction_response = await getAuction(state.eventID);
      if (auction_response === undefined) {
        handleLoading(false);
        console.log("FAILED TO RETRIEVE AUCTION DATA");
      }
      setAuction(auction_response.data.Item);

      // get art from Event ID
      const art_response = await getArt(state.eventID);

      let temp = [];
      // fill array with art data
      if (art_response !== null && art_response.data.Item !== null) {
        let arr = art_response.data.Items;
        arr.forEach((d, i) => {
          temp.push({
            id: i,
            title: d.title.S,
            medium: d.medium.S,
            year: d.year.N,
            dimension: d.dimension.S,
            min_price: d.min_price.N,
            image_ref: getImageLink(d.image_ref.S),
            link: d.link.S,
            artID: d.artID.S,
            description: d.description.S,
            artistID: d.artistID.S,
            buy_price: d.buy_price ? d.buy_price.N : "-1",
          });
        });
        for (var i = 0; i < temp.length; i++) {
          const artist_response = await getArtistByID(temp[i].artistID);
          temp[i].artist_name = artist_response.data.Item.full_name;
        }
      }

      setRows(temp);
      handleLoading(false);
      handleReload(false);
    }

    if (reload) {
      fetchData();
    }

    if (state.eventID !== "") {
      addCacheByPath("event", state.eventID);
      fetchData();
    } else {
      navigate("/events");
    }
  }, [navigate, reload, state.eventID]);

  let tempQRCode = {
    artID: "1dcba5fb75e",
    artistID: "9b86f9bfc92",
    artist_name: "Henry Dunkelberger",
    description: "blah blah",
    dimension: "20x20",
    id: 0,
    image_ref:
      "https://auctios-image-store.s3.amazonaws.com/thumbnails/dolphin.jpg",
    link: "https://app.auctios.xyz/display?id=1dcba5fb75e",
    medium: "Acrylic ink, acrylic paint, and oil pastel on paper",
    min_price: "300",
    title: "Autres lieux ephehemres II",
    year: 2022,
  };

  return false ? (
    <QRCodeSVG data={tempQRCode} />
  ) : loading ? (
    <LoadingScreen backEnabled={true} event={"loading"} editor={"art"} />
  ) : (
    <div>
      <Warning
        open={open}
        handleClose={handleClose}
        text={"Once you delete this piece, you will not be able to recover it."}
      />

      <Dash
        auction={auction}
        columns={columns}
        rows={rows}
        editMode={editMode}
        setEditMode={setEditMode}
        Component={ArtEditorPanel}
        handleReload={handleReload}
        live={false}
        mode={"art"}
        fail_message={`Select NEW PIECE to start adding art to your event. You must have at least one artist profile before adding art.`}
        computeMutation={computeMutation}
        mutateRow={mutateRow}
        setQRColorMode={setQRColorMode}
        qrColorMode={qrColorMode}
      />
    </div>
  );
}
