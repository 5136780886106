import JSZip from "jszip";
import { useState } from "react";
import { THEME } from "../../util/theme";
import { saveAs } from "file-saver";
import saveSvgAsPng from "save-svg-as-png";
import { Fab, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import { alpha, styled } from "@mui/material/styles";
import { EventEditorPopup } from "./EventEditorPopup";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#fff",
    "&:hover": {
      backgroundColor: alpha("#fff", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#fff",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#fff",
    "&:hover": {
      backgroundColor: alpha("#fff", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#fff",
  },
}));

const downloadAll = async (data) => {
  const imageOptions = {
    encoderOptions: 1,
  };
  const zip = new JSZip();
  let blobArray = [];
  const promises = [];
  data.forEach((obj) => {
    promises.push(
      saveSvgAsPng
        .svgAsPngUri(document.getElementById(obj.id), imageOptions)
        .then(async (uri) => {
          const png = await fetch(uri);
          const blob = await png.blob();
          blobArray.push({
            blob: blob,
            title: obj.title ? obj.title : obj.full_name,
          });
        })
    );
  });

  Promise.all(promises).then(async () => {
    blobArray.forEach((obj) => {
      zip.file(`${obj.title}.png`, obj.blob, { binary: true });
    });
    const r = await zip.generateAsync({ type: "blob" });
    saveAs(r, "labels.zip");
  });
};
function Header({ auction }) {
  const style = {
    header: {
      color: THEME.secondary_color,
      lineHeight: 0,
    },
    text: {
      color: THEME.secondary_color,
      fontSize: ".75em",
      lineHeight: 0,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      marginTop: 20,
      marginBottom: 20,
    },
  };
  return auction ? (
    <div style={style.container}>
      <h1 style={style.header}>{auction.eventID.S}</h1>
      {auction.start_time.S === null || auction.start_time.S === "" ? (
        <p style={style.text}>--/--/--</p>
      ) : (
        <p style={style.text}>
          {convertTimeToDate(auction.start_time.S)}
          {" to "}
          {convertTimeToDate(auction.end_time.S)}
        </p>
      )}
    </div>
  ) : null;
}

/** converts a timestamp, representated as a map, to a stringified time. */
function convertTimeToDate(_timestamp) {
  let timestamp = new Date(_timestamp);
  let date = timestamp.toLocaleString();

  let findex_1 = date.lastIndexOf("/") + 1;
  let findex_2 = date.lastIndexOf(":");

  return (
    date.substring(0, findex_1) +
    date.substring(findex_1 + 2, findex_2) +
    date.substring(findex_2 + 3)
  );
}

export default function ToolBar({
  setOpen,
  auction,
  mode,
  handleReload,
  data,
  downloadQR,
  live,
  chargeAccount,
  setQRColorMode,
  qrColorMode,
}) {
  const [editTimeOpen, setEditTimeOpen] = useState(false);

  return mode === "live" ? (
    <div>
      <Header auction={auction} />
    </div>
  ) : (
    <div>
      <Box style={style.toolBar}>
        <Fab
          color="inherit"
          aria-label="add"
          onClick={() => setOpen(true)}
          style={style.fabLeft}
          size="medium"
          variant="extended"
        >
          <AddIcon />
          {mode === "art" ? "new piece" : "new artist"}
        </Fab>

        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Header auction={auction} />
            <Fab
              aria-label="edit"
              onClick={() => setEditTimeOpen(true)}
              size="small"
              color="inherit"
              style={style.editButton}
            >
              <EditIcon style={{ fontSize: "16px" }} />
            </Fab>
          </Box>
        </div>
        {(mode === "art" || mode === "artist") && (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            style={style.toggleAndDownload}
          >
            {setQRColorMode && (
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={qrColorMode}
                    onChange={(event) => setQRColorMode(event.target.checked)}
                    color="secondary"
                  />
                }
                label={"Colored QRs"}
                style={style.switchControl}
                labelPlacement="start"
              />
            )}
            <Fab
              aria-label="download"
              onClick={() => downloadAll(data, downloadQR)}
              style={style.fabRight}
              color={qrColorMode ? "secondary" : "inherit"}
              variant="extended"
              size="medium"
            >
              <DownloadIcon />
              Labels
            </Fab>
          </Box>
        )}
      </Box>
      {auction ? (
        <EventEditorPopup
          open={editTimeOpen}
          setOpen={setEditTimeOpen}
          event={auction}
        />
      ) : null}
    </div>
  );
}

const style = {
  toolBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "relative",
  },
  fabLeft: {
    position: "absolute",
    left: 25,
  },
  fabRight: {
    position: "absolute",
  },
  toggleAndDownload: {
    position: "absolute",
    right: 25,
  },
  switchControl: {
    marginRight: 120,
    color: "white",
  },
  button: {
    width: "150px",
    backgroundColor: THEME.secondary_color,
    marginTop: "2.5%",
    marginBottom: "2.5%",
  },
  editButton: {
    top: 22,
    left: 10,
    height: 25,
    width: 25,
    minHeight: 25,
  },
  connected: {
    color: "black",
    backgroundColor: "white",
    marginTop: "1%",
    marginBottom: "1%",
    display: "flex",
    width: 165,
    justifyContent: "center",
    borderRadius: 5,
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
  },
  textConnected: {
    fontWeight: "bold",
  },
};
