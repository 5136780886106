import { CircularProgress } from "@mui/material";
import { THEME } from "../util/theme";
import { useWindowDimensions } from "../util/util";
import NavBar from "./NavBar";

export default function LoadingScreen({
  event,
  backEnabled,
  editor,
  _hide_nav,
}) {
  const { height } = useWindowDimensions();

  return (
    <div style={{ backgroundColor: THEME.primary_color, height: height }}>
      {_hide_nav ? null : (
        <NavBar event={event} backEnabled={backEnabled} editor={editor} />
      )}
      <div style={style.progress}>
        <CircularProgress style={{ color: "white" }} />
      </div>
    </div>
  );
}

const style = {
  progress: {
    display: "flex",
    justifyContent: "center",
    marginTop: "25%",
  },
};
