import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@mui/material";

import { THEME } from "../util/theme";

export default function Table({
  columns,
  rows,
  fail_message,
  computeMutation,
  mutateRow,
  handleReload,
}) {
  const [promiseArguments, setPromiseArguments] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const noButtonRef = React.useRef(null);

  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log(error);
  }, []);

  const processRowUpdate = React.useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    [computeMutation]
  );

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      handleReload(true);
      resolve(response);
      setPromiseArguments(null);
    } catch (error) {
      setSnackbar({ children: "Name can't be empty", severity: "error" });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  return rows.length === 0 ? (
    <div style={style.noDataContainer}>
      <p style={style.text}>{fail_message}</p>
    </div>
  ) : (
    <div style={style.container}>
      {renderConfirmDialog()}
      <DataGrid
        experimentalFeatures={{ newEditingApi: true }}
        rowsPerPageOptions={[10, 25, 50, 100]}
        autoHeight={true}
        rows={rows}
        columns={columns}
        pagination
        style={style.grid}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        processRowUpdate={processRowUpdate}
        getRowId={(row) => row.id + ""}
      />
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}

const style = {
  container: {
    flex: 3,
    backgroundColor: THEME.primary_color,
  },
  grid: {
    color: THEME.secondary_color,
  },
  text: {
    color: THEME.secondary_color,
    fontSize: "1.25 em",
  },
  noDataContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
  },
};
