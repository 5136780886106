import { createContext } from "react";
import { root } from "./aws";

export const initialState = {
  email: "",
  name: "",
  id: "",
  eventID: "",
  events: [],
  stripeAccount: "",
};

export const reducer = (_, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        email: action.payload.email,
        name: action.payload.name,
        id: action.payload.id,
        eventID: action.payload.eventID,
        events: action.payload.events,
        stripeAccount: action.payload.stripeAccount,
      };
    case "EVENT":
      return {
        eventID: action.payload.eventID,
        id: action.payload.id,
        events: action.payload.events,
      };
    case "DELETE":
      return {
        events: action.payload.events,
      };
    default:
      return;
  }
};

export const addCacheByPath = (path, response) => {
  // Converting our response into Actual Response form
  const data = new Response(JSON.stringify(response));

  if ("caches" in window) {
    // Opening given cache and putting our data into it
    caches.open("CACHE").then((cache) => {
      cache.put(root + "/" + path, data);
    });
  }
};
export const getCacheByPath = async (path) => {
  if (typeof caches === "undefined") return false;

  const cacheStorage = await caches.open("CACHE");
  const cachedResponse = await cacheStorage.match(root + "/" + path);

  // If no cache exists
  if (!cachedResponse || !cachedResponse.ok) {
    return null;
  }

  return cachedResponse.json().then((item) => {
    return item;
  });
};

export const clearCache = async () => {
  addCacheByPath("user", null);
  addCacheByPath("event", null);
};
export const AdminContext = createContext(initialState);
