import React, { useEffect, useContext, useState, useReducer } from "react";
import { Auth } from "aws-amplify";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import {
  AdminContext,
  getCacheByPath,
  initialState,
  reducer,
} from "./util/context";

import { getAdmin } from "./util/api";

import Login from "./screens/Login";
import Events from "./screens/Events";
import LoginFirstTime from "./screens/LoginFirstTime";
import { LOCAL } from "./util/constants";
import LiveDashboard from "./screens/LiveDashboard";
import ArtEditor from "./screens/ArtEditor";
import ArtistEditor from "./screens/ArtistEditor";
import Profile from "./screens/Profile";

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AdminContext.Provider value={[state, dispatch]}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/new-user" element={<LoginFirstTime />} />
          <Route
            path="/events"
            element={
              LOCAL ? (
                <Events />
              ) : (
                <RequireAuth>
                  <Events />
                </RequireAuth>
              )
            }
          />
          <Route
            path="/dashboard"
            element={
              LOCAL ? (
                <ArtEditor />
              ) : (
                <RequireAuth>
                  <ArtEditor />
                </RequireAuth>
              )
            }
          />
          <Route
            path="/live"
            element={
              LOCAL ? (
                <LiveDashboard />
              ) : (
                <RequireAuth>
                  <LiveDashboard />
                </RequireAuth>
              )
            }
          />
          <Route
            path="/artist-dashboard"
            element={
              LOCAL ? (
                <ArtistEditor />
              ) : (
                <RequireAuth>
                  <ArtistEditor />
                </RequireAuth>
              )
            }
          />
          <Route
            path="/profile"
            element={
              LOCAL ? (
                <Profile />
              ) : (
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              )
            }
          />
          <Route path="/" element={<Login />} />
        </Routes>
      </Router>
    </AdminContext.Provider>
  );
}

/**
 *  RequireAuth checks for an authentication change: if there is an authenticated
 *  user, request the data for that user via the user's email and returns the
 *  children of the component; otherwise, return the login component.
 */
function RequireAuth({ children }) {
  const [state, dispatch] = useContext(AdminContext);
  const [load, didLoad] = useState(false);

  /** loads the user via email into the local state */

  const location = useLocation();

  useEffect(() => {
    let cancel = false;

    async function loadUser(email) {
      if (cancel) return;
      const admin = await getAdmin(email);

      const cached_event = await getCacheByPath("event");

      dispatch({
        type: "LOGIN",
        payload: {
          email: admin.data.Item.email.S,
          name: admin.data.Item.name.S,
          id: admin.data.Item.adminID.S,
          eventID: cached_event,
          events: admin.data.Item.events.L,
          stripeAccount: admin.data.Item.stripeAccount.S,
        },
      });
      didLoad(true);
    }
    if (!load) {
      Auth.currentAuthenticatedUser()
        .then(async (res) => {
          loadUser(res.attributes.email);
          return children;
        })
        .catch((err) => {
          return <Navigate to="/login" state={{ from: location }} />;
        });
    }

    return () => {
      cancel = true;
    };
  }, [children, dispatch, load, location]);

  if (state.email !== "") {
    return children;
  }
  return null;
}

export default App;
