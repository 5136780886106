import { DARK_MODE } from "./constants";

const LIGHT_THEME = {
  primary_color: "#f6f6f6",
  secondary_color: "black",
};

const DARK_THEME = {
  primary_color: "black",
  secondary_color: "#f6f6f6",
};

export const THEME = DARK_MODE ? DARK_THEME : LIGHT_THEME;
