import { Auth } from "aws-amplify";
import { newAdmin } from "./api";
import { clearCache } from "./context";

export async function login(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      return { auth: false, user: user };
    } else {
      return { auth: true, user: user };
    }
  } catch (error) {
    console.log("error signing in", error);
    return { auth: false, user: null };
  }
}

export async function logout() {
  try {
    await Auth.signOut();
    clearCache();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

export async function requestNewPassword(username) {
  // Send confirmation code to user's email
  Auth.forgotPassword(username)
    .then((data) => console.log(data))
    .catch((err) => console.log(err));
}

export async function changePassword(username, code, new_password) {
  // Collect confirmation code and new password, then
  Auth.forgotPasswordSubmit(username, code, new_password)
    .then((data) => console.log(data))
    .catch((err) => console.log(err));
}

export async function loginNew(name, username, password, new_password) {
  try {
    const user = await Auth.signIn(username, password);
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      await Auth.completeNewPassword(
        user, // the Cognito User Object
        new_password // the new password
      );
      await newAdmin(username, name);
      return true;
    }
  } catch (error) {
    console.log("error signing in", error);
    return false;
  }
}
