import React, { useState, useContext } from "react";
import { TextField } from "@mui/material";
import { newArtist, uploadToAws } from "../../util/api";

import Loading from "./Loading";
import { THEME } from "../../util/theme";
import ButtonBar from "./ButtonBar";
import { AdminContext } from "../../util/context";
import ImageUpload from "./ImageUpload";

export default function ArtistEditorPanel({ setPanelOpen, handleReload }) {
  const [state] = useContext(AdminContext);
  const [fullName, handleFullName] = useState("");
  const [bio, handleBio] = useState("");
  const [medium, handleMedium] = useState("");
  const [social, handleSocial] = useState("");
  const [loading, handleLoading] = useState([false, ""]);
  const [imageRef, handleImageRef] = useState(null);

  const [fullNameError, setFullNameError] = useState(false);
  const [bioError, setBioError] = useState(false);
  const [mediumError, setMediumError] = useState(false);
  const [socialError, setSocialError] = useState(false);
  const [imageRefError, setImageRefError] = useState(false);

  /** ensures the form is properly filled before submitting. */
  function validate() {
    if (fullName === "") {
      setFullNameError(true);
      return false;
    }
    if (bio === "") {
      setBioError(true);
      return false;
    }
    if (medium === "") {
      setMediumError(true);
      return false;
    }
    if (social === "") {
      setSocialError(true);
      return false;
    }
    return true;
  }

  async function _addArtist() {
    handleLoading([true, "Adding artist to the Gallery..."]);
    let data = {
      full_name: fullName,
      bio: bio,
      medium: medium,
      social: social,
      eventID: state.eventID,
      image_ref: imageRef === null ? null : imageRef.name,
    };
    try {
      await newArtist(data);
      await uploadToAws(imageRef);
      handleLoading([false, ""]);
      setPanelOpen(false);
      handleReload(true);
    } catch (err) {
      handleLoading([false, ""]);
      console.log(err);
    }
  }

  function handleImageSelection(file) {
    if (file.type === "image/heic" || file.type === "image/tiff") {
      setImageRefError(true);
      return;
    }
    const obj = URL.createObjectURL(file);
    handleImageRef({
      uri: obj,
      name: file.name,
      type: file.type,
    });
  }

  return (
    <div style={style.outerContainer}>
      {loading[0] ? (
        <Loading text={loading[1]} />
      ) : (
        <div style={style.container}>
          <form noValidate autoComplete="off">
            <div style={style.inputContainer}>
              <TextField
                label="Full Name"
                variant="filled"
                value={fullName}
                onChange={(e) => handleFullName(e.target.value)}
                error={fullNameError}
                helperText={
                  fullNameError ? "Please enter the artist's full name" : ""
                }
                style={style.input}
              />
              <TextField
                label="Bio"
                variant="filled"
                multiline
                value={bio}
                onChange={(e) => handleBio(e.target.value)}
                error={bioError}
                helperText={bioError ? "Please enter the artist's bio" : ""}
                style={style.input}
              />
              <TextField
                label="Medium"
                variant="filled"
                value={medium}
                onChange={(e) => handleMedium(e.target.value)}
                error={mediumError}
                helperText={
                  mediumError ? "Please enter the artist's medium" : ""
                }
                style={style.input}
              />

              <TextField
                label="Social Media Handle"
                variant="filled"
                value={social}
                onChange={(e) => handleSocial(e.target.value)}
                error={socialError}
                helperText={
                  socialError
                    ? "Please enter the artist's social media handle"
                    : ""
                }
                style={style.input}
              />
              <ImageUpload
                imageRef={imageRef}
                imageRefError={imageRefError}
                handleImageSelection={handleImageSelection}
              />
              <ButtonBar validate={validate} handleSubmit={_addArtist} />
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

const style = {
  outerContainer: {
    display: "flex",
    flex: 1,
    overflow: "auto",
    maxHeight: 1000,
  },
  container: {
    backgroundColor: THEME.primary_color,
    display: "flex",
    alignItems: "flex-start",
    textAlign: "center",
    marginLeft: "5%",
    marginRight: "5%",
  },
  inputContainer: {},
  input: {
    marginTop: "5%",
    width: "100%",
    backgroundColor: THEME.secondary_color,
    borderRadius: 5,
  },
  dimensionSep: {
    fontWeight: "bolder",
    color: "gray",
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "6%",
  },
  dimensionInput: {
    marginTop: "5%",
    backgroundColor: THEME.secondary_color,
    borderRadius: 5,
  },
  dimensionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
};
