import React, { useState, useContext, useEffect } from "react";
import { addCacheByPath, AdminContext, getCacheByPath } from "../util/context";
import { IconButton } from "@mui/material";

import Dash from "../components/Dash";

import PaymentIcon from "@mui/icons-material/Payment";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import {
  captureBid,
  capturePayment,
  getArt,
  getAuction,
  getBidder,
  getImageLink,
  getUser,
} from "../util/api";
import { useNavigate } from "react-router-dom";
import logo from "../images/new_logo_black.svg";
import LoadingScreen from "../components/LoadingScreen";

function Component() {
  const componentStyle = {
    backgroundColor: "red",
    flex: 1,
    width: "100%",
  };
  return <div style={componentStyle}></div>;
}

export default function LiveDashboard() {
  const navigate = useNavigate();
  const [state] = useContext(AdminContext);
  const [auction, setAuction] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, handleLoading] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [auctionIsLive, handleAuctionIsLive] = useState(true);
  const small_width = 62.5;
  const small_flex = 0.25;
  const med_width = 125;
  const med_flex = 0.5;
  const large_width = 175;
  const large_flex = 0.75;

  useEffect(() => {
    async function getCachedUser() {
      if (state.stripeAccount) {
        setStripe(state.stripeAccount);
      } else {
        const res = await getCacheByPath("user");
        setStripe(res.stripeAccount);
      }
    }

    if (stripe === null) {
      getCachedUser();
    }
  }, [state.stripeAccount, stripe]);

  const chargeAccount = (data) => {
    const promises = [];
    data.forEach((obj) => {
      promises.push(placeCharge(obj));
    });
    Promise.all(promises).then(() => {
      handleAuctionIsLive(false);
    });
  };

  const placeCharge = async (user) => {
    const paymentObj = {
      amount: user.bid_price * 100,
      customerID: user.stripe_id,
      name: user.top_bidder,
      description: user.title + " by " + user.artist_name,
      phone_number: user.phone_number,
      eventID: state.eventID,
    };
    // ADD CHECK FOR ALREADY CAPTURED
    const success = await capturePayment(paymentObj);
    if (success) {
      handleLoading(true);
      await captureBid(user.bidID);
      handleLoading(false);
    }
  };

  const imageThumb = (params) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={params.row.image_ref} width={"60%"} alt={params.row.title} />
      </div>
    );
  };

  const delta = (params) => {
    const diff = params.row.bid_price - params.row.min_price;
    const color = diff > 0 ? "green" : "red";
    return (
      <div style={{ color: color }}>
        {diff > 0 ? "+" : ""}
        {diff}
      </div>
    );
  };

  const paymentButton = (params) => {
    return !params.row.captured ? (
      <div>
        <IconButton
          aria-label="payment"
          onClick={() => placeCharge(params.row)}
        >
          <PaymentIcon style={{ color: "white" }} />
        </IconButton>
      </div>
    ) : (
      <div>
        <IconButton aria-label="check">
          <CheckBoxIcon color={"success"} />
        </IconButton>
      </div>
    );
  };

  const _renderHeader = (title) => {
    return <span style={{ fontWeight: "bold" }}>{title}</span>;
  };

  const columns = [
    {
      field: "image",
      flex: small_flex,
      midWidth: small_width,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => imageThumb(params),
      renderHeader: () => _renderHeader(""),
    },
    {
      field: "title",
      flex: med_flex,
      midWidth: med_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Title"),
    },
    {
      field: "artist_name",
      flex: med_flex,
      midWidth: med_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Artist"),
    },
    {
      field: "price_diff",
      flex: small_flex,
      midWidth: small_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Delta"),
      renderCell: (params) => delta(params),
    },
    {
      field: "bid_price",
      flex: small_flex,
      midWidth: small_width,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => {
        return params.value === undefined ? "" : `$${params.value}`;
      },
      renderHeader: () => _renderHeader("Bid Price"),
    },
    {
      field: "sold",
      flex: small_flex,
      midWidth: small_width,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => {
        return params.value ? "TRUE" : "FALSE";
      },
      renderHeader: () => _renderHeader("Sold"),
    },
    {
      field: "top_bidder",
      flex: large_flex,
      midWidth: large_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Top Bidder"),
    },
    {
      field: "phone_number",
      flex: large_flex,
      midWidth: large_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Phone Number"),
    },
    // {
    //   field: "userID",
    //   flex: large_flex,
    //   midWidth: large_width,
    //   align: "center",
    //   headerAlign: "center",
    //   renderHeader: () => _renderHeader("Account"),
    //   valueFormatter: (params) => {
    //     return params.value === "AAA" ? "" : params.value;
    //   },
    // },
    // {
    //   field: "payment",
    //   flex: small_flex,
    //   midWidth: small_width,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (params) => paymentButton(params),
    //   renderHeader: () => _renderHeader(""),
    // },
  ];

  useEffect(() => {
    async function fetchData() {
      handleLoading(true);
      // get auction from Event ID
      const auction_response = await getAuction(state.eventID);
      if (auction_response === undefined) {
        handleLoading(false);
        console.log("FAILED TO RETRIEVE AUCTION DATA");
      }
      setAuction(auction_response.data.Item);

      // get art from Event ID
      const art_response = await getArt(state.eventID);

      let temp = [];
      // fill array with art data
      if (art_response !== null && art_response.data.Item !== null) {
        let arr = art_response.data.Items;
        arr.forEach((d, i) => {
          if (d.top_bidder.S !== "") {
            temp.push({
              id: i,
              title: d.title.S,
              artist_name: d.artist_name.S,
              min_price: d.min_price.N,
              top_bidder: d.top_bidder.S,
              image_ref: d.image_ref.S,
              sold: d.sold ? d.sold.BOOL : false,
            });
          }
        });

        // fill array with bidder data
        for (var i = 0; i < temp.length; i++) {
          const bidder_response = await getBidder(temp[i].top_bidder);
          temp[i].userID = bidder_response.data.Item.userID;
          temp[i].bid_price = bidder_response.data.Item.price;
          temp[i].captured = bidder_response.data.Item.captured;
          temp[i].bidID = temp[i].top_bidder;

          const user_response = await getUser(temp[i].userID);
          temp[i].top_bidder = user_response.data.Item.full_name;
          temp[i].phone_number = user_response.data.Item.phone_number;
          temp[i].stripe_id = user_response.data.Item.stripe_id;

          if (temp[i].image_ref !== null) {
            const image_ref = getImageLink(temp[i].image_ref);
            temp[i].image_ref = image_ref;
          } else {
            temp[i].image_ref = logo;
          }
        }
        if (temp[0] && temp[0].captured) {
          handleAuctionIsLive(false);
        }
      }
      // add empty row
      setRows(temp);
      handleLoading(false);
    }

    if (state.eventID !== "") {
      addCacheByPath("event", state.eventID);
      fetchData();
    } else {
      navigate("/events");
    }
  }, [navigate, state.eventID]);

  return loading ? (
    <LoadingScreen backEnabled={true} event={"loading"} editor={"live"} />
  ) : (
    <Dash
      auction={auction}
      columns={columns}
      rows={rows}
      Component={Component}
      live={auctionIsLive}
      mode={"live"}
      fail_message={`Check back when bids have been placed.`}
      chargeAccount={chargeAccount}
    />
  );
}
