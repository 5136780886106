import React, { useState, useContext, useEffect } from "react";
import { addCacheByPath, AdminContext } from "../util/context";
import { Alert, IconButton, Snackbar } from "@mui/material";

import {
  deleteArtist,
  getArtByArtistID,
  getArtist,
  getAuction,
  getImageLink,
  updateArtist,
  uploadToAws,
} from "../util/api";
import { useNavigate } from "react-router-dom";
import Dash from "../components/Dash";
import Warning from "../components/Warning";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCodeIcon from "@mui/icons-material/QrCode";
import saveSvgAsPng from "save-svg-as-png";
import ArtistEditorPanel from "../components/dashboard/ArtistEditorPanel";
import LoadingScreen from "../components/LoadingScreen";
import ArtistQR from "../components/ArtistQR";

function computeMutation(newRow, oldRow) {
  if (newRow.full_name !== oldRow.full_name && newRow.full_name !== "") {
    return `Full_name from '${oldRow.full_name}' to '${newRow.full_name}'`;
  }
  if (newRow.bio !== oldRow.bio && newRow.bio !== "") {
    return `Bio from '${oldRow.bio}' to '${newRow.bio}'`;
  }
  if (newRow.medium !== oldRow.medium && newRow.medium !== "") {
    return `Medium from '${oldRow.medium}' to '${newRow.medium}'`;
  }
  if (newRow.social !== oldRow.social && newRow.social !== "") {
    return `Social from '${oldRow.social}' to '${newRow.social}'`;
  }
  return null;
}

function mutateRow(newRow) {
  const data = {
    full_name: newRow.full_name,
    bio: newRow.bio,
    medium: newRow.medium,
    social: newRow.social,
  };
  return updateArtist(newRow.artistID, data);
}

/** Dashboard component displays the current selected auction */
export default function ArtistEditor() {
  const navigate = useNavigate();
  const [state] = useContext(AdminContext);
  const [auction, setAuction] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, handleLoading] = useState(false);
  const [reload, handleReload] = useState(false);

  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const small_width = 30;
  const small_flex = 0.05;
  const med_width = 125;
  const med_flex = 0.1;
  const large_width = 175;
  const large_flex = 1;

  const [open, setOpen] = useState(false);
  const [deleteData, handleDeleteData] = useState({});

  async function checkArtByArtist(artistID) {
    const art = await getArtByArtistID(artistID);
    return art.data.Count > 0;
  }

  /** handleClickOpen is called when a user presses the delete button. */
  const handleClickOpen = async (artistID) => {
    const shouldDelete = await checkArtByArtist(artistID);
    if (shouldDelete) {
      setSnackbar({
        children: "Can't remove artist until all associated art is deleted.",
        severity: "error",
      });
    } else {
      setOpen(true);
    }
  };

  /** handleClose occurs when a piece is about to be deleted. If deleteState
   *  is true, then calls _deleteArtist(); otherwise does nothing.
   */
  const handleClose = (deleteState) => {
    setOpen(false);
    if (!deleteState) {
      _deleteArtist();
    }
  };

  async function _deleteArtist() {
    handleLoading(true);
    await deleteArtist(deleteData.artistID, deleteData.image_ref);
    handleReload(true);
    handleLoading(false);
  }

  const downloadQR = (id, title) => {
    let svg = document.getElementById(id);
    saveSvgAsPng.saveSvgAsPng(svg, `${title}.png`, {
      encoderOptions: 1,
    });
  };

  const tools = (params) => {
    const qr_data = {
      ...params.row,
      link: `https://app.auctios.xyz/artist-display?artist=${params.row.artistID}`,
    };
    return params.row.image_ref !== undefined ? (
      <div>
        <IconButton
          aria-label="edit"
          onClick={() => downloadQR(params.row.id, params.row.full_name)}
        >
          <QrCodeIcon style={{ color: "white" }} />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => {
            handleDeleteData(params.row);
            handleClickOpen(params.row.artistID);
          }}
        >
          <DeleteIcon style={{ color: "white" }} />
        </IconButton>
        <ArtistQR data={qr_data} />
      </div>
    ) : (
      ""
    );
  };

  async function handleImageFile(file, row) {
    if (
      !file.type.startsWith("image/") ||
      file.type === "image/heic" ||
      file.type === "image/tiff"
    ) {
      return;
    }

    const obj = URL.createObjectURL(file);
    const image_ref = {
      uri: obj,
      name: file.name,
      type: file.type,
    };
    const data = {
      full_name: row.full_name,
      bio: row.bio,
      medium: row.medium,
      social: row.social,
      image_ref: image_ref === null ? null : image_ref.name,
    };
    const res = await updateArtist(row.artistID, data);
    if (res === null) {
    } else {
      await uploadToAws(image_ref);
      handleReload(true);
    }
  }

  function handleThumbnailClick(row) {
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = (event) => {
      handleImageFile(event.target.files[0], row);
    };
    input.click();
  }

  const imageThumb = (params) => {
    return (
      <div
        style={{ display: "flex", justifyContent: "center" }}
        onClick={() => handleThumbnailClick(params.row)}
      >
        <img src={params.row.image_ref} width={"60%"} alt={params.row.title} />
      </div>
    );
  };

  const _renderHeader = (title) => {
    return <span style={{ fontWeight: "bold" }}>{title}</span>;
  };

  const columns = [
    {
      field: "image",
      flex: small_flex,
      midWidth: small_width,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => imageThumb(params),
      renderHeader: () => _renderHeader(""),
    },
    {
      field: "full_name",
      flex: med_flex,
      midWidth: med_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Full Name"),
      editable: true,
    },
    {
      field: "medium",
      flex: med_flex,
      midWidth: med_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Medium"),
      editable: true,
    },
    {
      field: "social",
      flex: med_flex,
      midWidth: med_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Social"),
      editable: true,
    },
    {
      field: "bio",
      flex: large_flex,
      midWidth: large_width,
      align: "center",
      headerAlign: "center",
      renderHeader: () => _renderHeader("Bio"),
      editable: true,
    },
    {
      field: "tools",
      flex: med_flex,
      midWidth: med_width,
      renderCell: (params) => tools(params),
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params,
      renderHeader: () => _renderHeader(""),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      handleLoading(true);
      // get auction from Event ID
      const auction_response = await getAuction(state.eventID);
      if (auction_response === undefined) {
        handleLoading(false);
        console.log("FAILED TO RETRIEVE AUCTION DATA");
      }
      setAuction(auction_response.data.Item);

      // get art from Event ID
      const artist_response = await getArtist(state.eventID);

      let temp = [];
      // fill array with art data
      if (artist_response !== null && artist_response.data.Item !== null) {
        let arr = artist_response.data.Items;
        arr.forEach((d, i) => {
          temp.push({
            id: i,
            full_name: d.full_name.S,
            bio: d.bio.S,
            medium: d.medium.S,
            social: d.social.S,
            image_ref: getImageLink(d.image_ref.S),
            eventID: d.eventID.S,
            artistID: d.artistID.S,
          });
        });
      }

      setRows(temp);
      handleLoading(false);
      handleReload(false);
    }

    if (reload) {
      fetchData();
    }

    if (state.eventID !== "") {
      addCacheByPath("event", state.eventID);
      fetchData();
    } else {
      navigate("/events");
    }
  }, [navigate, reload, state.eventID]);

  return loading ? (
    <LoadingScreen backEnabled={true} event={"loading"} editor={"artist"} />
  ) : (
    <div>
      <Warning
        open={open}
        handleClose={handleClose}
        text={
          "Once you delete this artist, you will not be able to recover it."
        }
      />
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <Dash
        auction={auction}
        columns={columns}
        rows={rows}
        Component={ArtistEditorPanel}
        handleReload={handleReload}
        live={false}
        mode={"artist"}
        fail_message={`Select NEW ARTIST to start adding artists to your event.`}
        computeMutation={computeMutation}
        mutateRow={mutateRow}
      />
    </div>
  );
}
