import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { THEME } from "../../util/theme";

export default function ButtonBar({ validate, handleSubmit }) {
  return (
    <div style={style.container}>
      <Button
        style={style.addButton}
        variant={"outlined"}
        color={"inherit"}
        onClick={() => (validate() ? handleSubmit() : null)}
        startIcon={<AddIcon />}
      >
        Add to gallery
      </Button>
    </div>
  );
}

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
    marginBottom: "5%",
  },
  addButton: {
    width: "75%",
    backgroundColor: THEME.secondary_color,
  },
};
