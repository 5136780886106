import React, { useState, useContext, useEffect } from "react";
import { Button, Grid, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { addCacheByPath, AdminContext, getCacheByPath } from "../util/context";
import { deleteAuction, getAuction, updateAdmin } from "../util/api";

import Warning from "../components/Warning";
import NavBar from "../components/NavBar";
import { THEME } from "../util/theme";
import { useWindowDimensions } from "../util/util";
import Event from "../components/event/Event";
import { NewEventPopup } from "../components/NewEventPopup";
import LoadingScreen from "../components/LoadingScreen";

function sortEventsByDate(a, b) {
  // if either of the objects are empty, they should come first
  if (!Object.keys(a).length) return -1;
  if (!Object.keys(b).length) return 1;

  // if the start_time is missing or empty, it should come first
  if (!a.start_time || !a.start_time.S) return -1;
  if (!b.start_time || !b.start_time.S) return 1;

  // normal date comparison
  return new Date(b.start_time.S) - new Date(a.start_time.S);
}

export default function Events() {
  const [state, dispatch] = useContext(AdminContext);
  const [isCached, setIsCached] = useState(false);
  const [events, setEvents] = useState(state.events);
  const [DATA, setDATA] = useState(null);

  const [deleteState, setDeleteState] = useState(null);
  const [loading, handleLoading] = useState(false);
  const { height } = useWindowDimensions();
  const [open, setOpen] = useState(false);

  const [name, setName] = useState(state.name);

  useEffect(() => {
    const fetchCache = async () => {
      if (!name) {
        const cached = await getCacheByPath("admin");
        if (cached && cached.name) {
          setName(cached.name.S);
        }
      }
    };

    fetchCache();
  }, [name]);

  const handleClickOpen = (obj) => {
    setDeleteState(obj);
  };

  useEffect(() => {
    async function fetchCache() {
      const cached = await getCacheByPath("user");
      if (cached === null) {
        addCacheByPath("user", state);
      }
      setIsCached(true);
    }
    if (!isCached) {
      fetchCache();
    }
  }, [isCached, state]);

  const handleClose = (shouldDelete) => {
    if (!shouldDelete) {
      _deleteAuction(deleteState);
      setDATA(null);
    }
    setDeleteState(null);
  };

  async function getEventData(eventID) {
    let auction = await getAuction(eventID.S);
    return auction.data.Item;
  }

  const _deleteAuction = async (obj) => {
    let events = state.events;
    let filtered = events.filter((v) => v.S !== obj.eventID.S);
    setEvents(filtered);
    await updateAdmin(state.id, { events: filtered });
    deleteAuction(obj.auctionID.S);
    dispatch({
      type: "DELETE",
      payload: {
        events: filtered,
      },
    });
  };

  const AddItem = () => {
    return (
      <Paper style={style.item}>
        <Button
          style={{ ...style.addItemTextContainer, height: "250px" }}
          color={"inherit"}
          onClick={() => setOpen(true)}
        >
          <p style={style.addItemText}>
            <AddIcon style={style.addIcon} />
          </p>
          <p style={style.itemLabel}> Create New Event</p>
        </Button>
      </Paper>
    );
  };

  useEffect(() => {
    let cancel = false;

    async function loadData() {
      if (cancel) return;
      handleLoading(true);
      let data = await Promise.all(events.map(getEventData));
      data.push({});
      setDATA(data);
      handleLoading(false);
    }
    if (DATA === null) {
      loadData();
    }

    return () => {
      cancel = true;
    };
  }, [DATA, events]);

  return DATA !== null && !loading ? (
    <div style={{ backgroundColor: THEME.primary_color, height: height }}>
      <NavBar event={`${name}'s Events`} backEnabled={false} />

      <Warning
        handleClose={handleClose}
        open={deleteState !== null}
        text={"Once you delete this event, you will not be able to recover it."}
      />
      <div style={style.eventContainer}>
        {
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 6, s: 10, md: 20, lg: 30, xl: 36 }}
          >
            {DATA.slice(0)
              .sort(sortEventsByDate)
              .map((obj, index) => (
                <Grid item xs={2} sm={3} md={4} key={index}>
                  {index === 0 ? (
                    <AddItem />
                  ) : (
                    <Event obj={obj} _onDelete={handleClickOpen} />
                  )}
                </Grid>
              ))}
          </Grid>
        }
      </div>
      <NewEventPopup open={open} setOpen={setOpen} />
    </div>
  ) : (
    <LoadingScreen backEnabled={false} event={`${name}'s Events`} />
  );
}

const style = {
  item: {
    border: "2px solid",
    borderColor: THEME.secondary_color,
    borderRadius: 5,
    backgroundColor: THEME.primary_color,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: 200,
  },
  addItemTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    border: "5px solid",
    borderRadius: 15,
    borderColor: THEME.primary_color,
    backgroundColor: THEME.secondary_color,
  },
  addItemText: {
    color: THEME.secondary_color,
    marginBottom: 0,
    marginTop: 10,
    fontSize: "1.5em",
    fontWeight: "bold",
  },
  itemLabel: {
    fontSize: "1em",
    color: THEME.secondary_color,
  },
  addIcon: {
    fontSize: "3.5em",
    color: THEME.secondary_color,
    fontWeight: "50",
  },
  eventContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "10%",
    marginLeft: "5%",
    marginRight: "5%",
  },
};
