import React, { useState, useContext } from "react";
import { Paper } from "@mui/material";
import { THEME } from "../../util/theme";
import TopBar from "./TopBar";

import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../util/context";

export default function Event({ obj, _onDelete }) {
  const [state, dispatch] = useContext(AdminContext);
  const navigate = useNavigate();
  const [_hover, _setHover] = useState(false);

  async function _onClick(eventID) {
    dispatch({
      type: "EVENT",
      payload: {
        eventID: eventID,
        id: state.id,
        events: state.events,
      },
    });
    navigate("/artist-dashboard");
  }

  const Item = ({ obj }) => {
    return (
      <Paper
        style={
          _hover
            ? hoverStyle
            : obj.active.BOOL
            ? style.item
            : { ...style.item, ...style.inactive }
        }
        onMouseOver={() => _setHover(true)}
        onMouseLeave={() => _setHover(false)}
        onClick={() => _onClick(obj.eventID.S)}
      >
        <TopBar obj={obj} _onDelete={_onDelete} />
        <p style={style.text}>{obj.eventID.S}</p>
      </Paper>
    );
  };

  return <Item obj={obj} />;
}

const style = {
  item: {
    border: "2px solid",
    borderColor: THEME.secondary_color,
    borderRadius: 5,
    backgroundColor: THEME.primary_color,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: 200,
  },
  inactive: {
    opacity: 0.5,
  },
  text: {
    color: THEME.secondary_color,
    fontSize: "1.25em",
    fontWeight: "500",
    textAlign: "center",
    marginRight: "10%",
    marginLeft: "10%",
  },
};

const hoverStyle = {
  ...style.item,
  backgroundColor: `${THEME.secondary_color}22`,
  cursor: "pointer",
};
