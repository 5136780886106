import React, { useState } from "react";
import NavBar from "../components/NavBar";
import Table from "./Table";
import SideBar from "./dashboard/SideBar";
import { THEME } from "../util/theme";
import { useWindowDimensions } from "../util/util";
import Panel from "./dashboard/Panel";
import ToolBar from "./dashboard/ToolBar";

export default function Dash({
  auction,
  columns,
  rows,
  Component,
  handleReload,
  live,
  mode,
  fail_message,
  computeMutation,
  mutateRow,
  downloadQR,
  chargeAccount,
  setQRColorMode,
  qrColorMode,
}) {
  const { height } = useWindowDimensions();
  const [open, setOpen] = useState(false);
  return (
    <div style={{ backgroundColor: THEME.primary_color, height: height }}>
      <NavBar event={mode} backEnabled={true} editor={mode} />
      <ToolBar
        setOpen={setOpen}
        auction={auction}
        mode={mode}
        handleReload={handleReload}
        data={rows}
        downloadQR={downloadQR}
        live={live}
        chargeAccount={chargeAccount}
        setQRColorMode={setQRColorMode}
        qrColorMode={qrColorMode}
      />

      <div>
        <Panel open={open} setOpen={setOpen}>
          <SideBar
            Component={Component}
            auction={auction}
            setPanelOpen={setOpen}
            handleReload={handleReload}
            mode={mode}
          />
        </Panel>
        <Table
          columns={columns}
          rows={rows}
          fail_message={fail_message}
          computeMutation={computeMutation}
          mutateRow={mutateRow}
          handleReload={handleReload}
        />
      </div>
    </div>
  );
}
