import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { logout } from "../util/auth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { THEME } from "../util/theme";
import UserLogo from "./UserLogo";
import SelectView from "./SelectView";

function BackButton({ navigate }) {
  return (
    <Button
      startIcon={<ArrowBackIcon />}
      color="inherit"
      onClick={() => navigate("/events")}
    >
      Events
    </Button>
  );
}

export default function NavBar({ event, backEnabled, editor }) {
  const navigate = useNavigate();
  function _logout() {
    logout();
    navigate("/login");
  }

  function _profile() {
    navigate("/profile");
  }

  return event === "profile" ? (
    <Box sx={{ flexGrow: 1, backgroundColor: THEME.secondary_color }}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <BackButton navigate={navigate} />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center" }}
          ></Typography>
          <Button color="inherit" onClick={_logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  ) : event === "loading" ? (
    <Box sx={{ flexGrow: 1, backgroundColor: THEME.secondary_color }}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <BackButton navigate={navigate} />
          <SelectView editor={editor} />
          <Button color="inherit" onClick={_profile}>
            Profile
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  ) : (
    <Box sx={{ flexGrow: 1, backgroundColor: THEME.secondary_color }}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          {backEnabled ? <BackButton navigate={navigate} /> : <UserLogo />}
          {!backEnabled ? (
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, textAlign: "center" }}
            >
              {event !== undefined ? event : "Events"}
            </Typography>
          ) : (
            <SelectView editor={editor} />
          )}
          <Button color="inherit" onClick={_profile}>
            Profile
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
