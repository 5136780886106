import { THEME } from "../../util/theme";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { convertTimeToDate } from "../../util/api";

export default function TopBar({ obj, _onDelete }) {
  const [_hover, _setHover] = useState(false);
  return (
    <div style={style.container}>
      {obj.start_time.S === "" || !obj.start_time.S ? (
        <p style={style.time}>--/--/--</p>
      ) : (
        <p style={style.time}>{convertTimeToDate(obj.start_time.S)}</p>
      )}
      <IconButton
        style={style.button}
        aria-label="delete"
        onClick={(event) => {
          event.stopPropagation();
          _onDelete(obj);
        }}
        onMouseOver={() => _setHover(true)}
        onMouseLeave={() => _setHover(false)}
      >
        <CloseRoundedIcon style={_hover ? style.hoverIcon : style.icon} />
      </IconButton>
    </div>
  );
}

const style = {
  container: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "5%",
    marginRight: "5%",
  },
  time: {
    color: THEME.secondary_color,
    fontSize: "1.25em",
    flex: 5,
    marginLeft: "5%",
  },

  button: {
    color: THEME.secondary_color,
    padding: 0,
    flex: 1,
  },
  icon: {
    fontSize: "1.25em",
    color: `${THEME.secondary_color}55`,
  },
  hoverIcon: {
    fontSize: "1.25em",
    color: `${THEME.secondary_color}99`,
  },
};
