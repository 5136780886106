import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import NavBar from "../components/NavBar";
import { generateStripeLink, getStripeAccount } from "../util/api";
import { getCacheByPath } from "../util/context";
import { THEME } from "../util/theme";
import { useWindowDimensions } from "../util/util";
import LoadingScreen from "../components/LoadingScreen";
export default function Profile() {
  const [cache, setCache] = useState(null);
  const [account, setAccount] = useState(null);
  const { height } = useWindowDimensions();

  useEffect(() => {
    async function fetchCache() {
      const cached = await getCacheByPath("user");
      setCache(cached);
    }
    if (cache === null) {
      fetchCache();
    }
  }, [cache]);

  useEffect(() => {
    async function getAccount() {
      const res = await getStripeAccount(cache.stripeAccount);
      setAccount(res.data.account);
    }
    if (cache !== null && account === null) {
      getAccount();
    }
  }, [cache, account]);

  const genLink = async () => {
    const data = await generateStripeLink(cache.stripeAccount);
    window.open(data.data.accountLink.url, "_blank", "noreferrer");
  };

  return (
    <div style={{ backgroundColor: THEME.primary_color, height: height }}>
      <NavBar event={"profile"} backEnabled={true} />

      {cache === null || account === null ? (
        <LoadingScreen _hide_nav={true} />
      ) : (
        <div style={style.container}>
          <h3 style={style.text}>{`Account Information`}</h3>
          <p style={style.text}>{`Email: ${cache.email}`}</p>
          <p style={style.text}>{`Name: ${cache.name}`}</p>
          {account.capabilities.card_payments === "pending" ? (
            <div style={style.connected}>
              <p style={style.textConnected}>Stripe Pending</p>
            </div>
          ) : !account.charges_enabled ? (
            <Button variant={"outlined"} onClick={genLink} style={style.button}>
              Connect Stripe
            </Button>
          ) : (
            <div style={style.connected}>
              <p style={style.textConnected}>Stripe Connected</p>
            </div>
          )}
          <h5
            style={style.text}
          >{`Connect stripe account to authorize payments.`}</h5>
        </div>
      )}
    </div>
  );
}

const style = {
  button: {
    color: "black",
    backgroundColor: "white",
    marginTop: "5%",
    marginBottom: "-1%",
  },
  connected: {
    color: "black",
    backgroundColor: "white",
    marginTop: "5%",
    marginBottom: "-1%",
    display: "flex",
    width: 165,
    justifyContent: "center",
    borderRadius: 5,
  },
  textConnected: {
    fontWeight: "bold",
  },
  container: {
    marginTop: "5%",
    marginLeft: "5%",
  },
  text: {
    color: "white",
  },
  outer: {
    display: "flex",
    border: "1px solid red",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};
