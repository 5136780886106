import { Drawer } from "@mui/material";
import React from "react";
import { THEME } from "../../util/theme";

export default function Panel({ children, setOpen, open }) {
  return (
    <div style={style.container}>
      <Drawer
        anchor={"left"}
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: style.container,
        }}
      >
        {children}
      </Drawer>
    </div>
  );
}

const style = {
  container: {
    backgroundColor: THEME.primary_color,
    width: 300,
  },
};
