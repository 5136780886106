import React, { useState, useContext, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getArtist, newArt, uploadToAws } from "../../util/api";

import { AdminContext } from "../../util/context";

import Loading from "./Loading";
import { THEME } from "../../util/theme";
import ImageUpload from "./ImageUpload";
import ButtonBar from "./ButtonBar";

const ArtistSelect = ({ handleSelect, error, eventID }) => {
  const [artistList, setArtistList] = useState(null);
  const [value, handleValue] = useState("");

  useEffect(() => {
    let cancel = false;
    async function fetch() {
      if (cancel) return;
      const res = await getArtist(eventID);
      let arr = {};
      res.data.Items.forEach((item) => {
        arr[item.artistID.S] = item.full_name.S;
      });
      setArtistList(arr);
    }
    if (artistList === null) {
      fetch();
    }
    return () => {
      cancel = true;
    };
  }, [artistList, eventID]);
  return (
    <FormControl fullWidth style={style.input} variant="filled">
      <InputLabel id="artist_name_input">Artist</InputLabel>
      <Select
        labelId="artist_name_input"
        id="demo-simple-select"
        value={value}
        error={error}
        label="Artist"
        onChange={(event) => {
          handleValue(event.target.value);
          handleSelect({
            id: event.target.value,
            full_name: artistList[event.target.value],
          });
        }}
      >
        <MenuItem disabled value="a">
          <em>Add new artists in artist editor</em>
        </MenuItem>
        {artistList
          ? Object.entries(artistList).map((item) => (
              <MenuItem key={item[0]} value={item[0]}>
                {item[1]}
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
};

export default function ArtEditorPanel({ setPanelOpen, handleReload }) {
  const [title, handleTitle] = useState("");
  const [medium, handleMedium] = useState("");
  const [description, handleDescription] = useState("");
  const [year, handleYear] = useState("");
  const [dimensionWidth, handleDimensionWidth] = useState("");
  const [dimensionHeight, handleDimensionHeight] = useState("");
  const [minPrice, handleMinPrice] = useState("");
  const [imageRef, handleImageRef] = useState(null);
  const [state] = useContext(AdminContext);
  const [loading, handleLoading] = useState([false, ""]);

  const [artistError, setArtistError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [minPriceError, setMinPriceError] = useState(false);
  const [imageRefError, setImageRefError] = useState(false);

  const [mediumError, setMediumError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [dimensionWidthError, setDimensionWidthError] = useState(false);
  const [dimensionHeightError, setDimensionHeightError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const [artist, handleArtist] = useState(null);

  // useEffect(() => {
  //   async function edit() {
  //     setEditing(true);
  //     console.log("EDITING", editMode[1]);
  //     // handleTitle(location.state.title);
  //     // handleArtistName(location.state.artist_name);
  //     // handleMinPrice(location.state.min_price);
  //     // handleImageRef({
  //     //   uri: location.state.image_ref,
  //     // });
  //     // handleMedium(location.state.medium);
  //     // handleYear(location.state.year);
  //     // handleDescription(location.state.description);
  //     // parseDimension(location.state.dimension);
  //   }
  //   // if (editMode[0]) {
  //     edit();
  //   }
  // }, [editMode, editing, location.state]);

  /** converts the selected image file into an uri object that can be sent to
   *  the backend.
   */

  /** ensures the form is properly filled before submitting. */
  function validate() {
    if (title === "") {
      setTitleError(true);
      return false;
    }
    if (artist === null) {
      setArtistError(true);
      return false;
    }
    if (artist.full_name === "") {
      setArtistError(true);
      return false;
    }
    if (artist.id === "") {
      setArtistError(true);
      return false;
    }
    if (medium === "") {
      setMediumError(true);
      return false;
    }
    if (year === "" || isNaN(Number(year)) || Number(year) < 0) {
      setYearError(true);
      return false;
    }
    if (dimensionHeight === "" || dimensionWidth === "") {
      setDimensionWidthError(true);
      setDimensionHeightError(true);
      return false;
    }
    if (minPrice === "" || isNaN(Number(minPrice)) || Number(minPrice) < 0) {
      setMinPriceError(true);
      return false;
    }
    if (imageRef === null) {
      setImageRefError(true);
      return false;
    }
    if (description === "") {
      setDescriptionError(true);
      return false;
    }

    return true;
  }

  function handleImageSelection(file) {
    if (file.type === "image/heic" || file.type === "image/tiff") {
      setImageRefError(true);
      return;
    }
    const obj = URL.createObjectURL(file);
    handleImageRef({
      uri: obj,
      name: file.name,
      type: file.type,
    });
  }

  async function _addArt() {
    handleLoading([true, "Consigning item to Auctios Gallery..."]);
    let data = {
      eventID: state.eventID,
      title: title,
      min_price: Number(minPrice),
      image_ref: imageRef === null ? null : imageRef.name,
      artist_name: artist.full_name,
      artistID: artist.id,
      medium: medium,
      year: Number(year),
      dimension: dimensionWidth + "x" + dimensionHeight,
      top_bidder: "",
      description: description,
    };
    const res = await newArt(data);
    if (res === null) {
      handleLoading([false, ""]);
    } else {
      await uploadToAws(imageRef);
      handleLoading([false, ""]);
      setPanelOpen(false);
      handleReload(true);
    }
  }

  // async function _updateArt() {
  //   handleLoading([true, "Updating item..."]);
  //   let data = {
  //     eventID: state.eventID,
  //     title: title,
  //     min_price: Number(minPrice),
  //     artist_name: artistName,
  //     medium: medium,
  //     year: Number(year),
  //     dimension: dimensionWidth + "x" + dimensionHeight,
  //     // description: description
  //   };
  //   if (imageRef.name !== undefined) {
  //     data.image_ref = imageRef.name;
  //   }
  //   await updateArt(location.state.artID, data);
  //   clear();
  //   if (imageRef.uri !== location.state.image_ref) {
  //     uploadToAws(imageRef);
  //   }
  //   handleLoading([false, ""]);
  //   navigate("/dashboard");
  // }

  // async function _deleteArt() {
  //   handleLoading([true, "Removing item from Auctios Gallery..."]);
  //   await deleteArt(location.state.artID, imageRef.uri);
  //   clear();
  //   handleLoading([false, ""]);
  //   navigate("/dashboard");
  // }

  return (
    <div style={style.outerContainer}>
      {loading[0] ? (
        <Loading text={loading[1]} />
      ) : (
        <div style={style.container}>
          <form noValidate autoComplete="off">
            <div style={style.inputContainer}>
              <TextField
                label="Title"
                variant="filled"
                onChange={(event) => handleTitle(event.target.value)}
                style={style.input}
                value={title}
                error={titleError}
              />
              <ArtistSelect
                error={artistError}
                eventID={state.eventID}
                handleSelect={handleArtist}
              />
              <TextField
                label="Medium"
                variant="filled"
                onChange={(event) => handleMedium(event.target.value)}
                style={style.input}
                id="medium_input"
                value={medium}
                error={mediumError}
              />
              <TextField
                label="Year"
                variant="filled"
                onChange={(event) => handleYear(event.target.value)}
                style={style.input}
                id="year_input"
                type="number"
                value={year}
                error={yearError}
              />
              <div style={style.dimensionContainer}>
                <TextField
                  label="Width (in)"
                  variant="filled"
                  onChange={(event) => handleDimensionWidth(event.target.value)}
                  id="dim_w_input"
                  value={dimensionWidth}
                  error={dimensionWidthError}
                  type="number"
                  style={style.dimensionInput}
                />
                <p style={style.dimensionSep}>X</p>
                <TextField
                  label="Height (in)"
                  variant="filled"
                  onChange={(event) =>
                    handleDimensionHeight(event.target.value)
                  }
                  style={style.dimensionInput}
                  id="dim_h_input"
                  value={dimensionHeight}
                  error={dimensionHeightError}
                  type="number"
                />
              </div>
              <TextField
                label="Minimum Price"
                variant="filled"
                type="number"
                onChange={(event) => handleMinPrice(event.target.value)}
                style={style.input}
                value={minPrice}
                error={minPriceError}
              />
              <TextField
                label="Description"
                variant="filled"
                multiline
                onChange={(event) => handleDescription(event.target.value)}
                style={style.input}
                value={description}
                error={descriptionError}
              />
            </div>
            <ImageUpload
              imageRef={imageRef}
              imageRefError={imageRefError}
              handleImageSelection={handleImageSelection}
            />
            <ButtonBar validate={validate} handleSubmit={_addArt} />
          </form>
        </div>
      )}
    </div>
  );
}

const style = {
  outerContainer: {
    display: "flex",
    flex: 1,
    overflow: "auto",
    maxHeight: 1000,
  },
  container: {
    backgroundColor: THEME.primary_color,
    display: "flex",
    alignItems: "flex-start",
    textAlign: "center",
    marginLeft: "5%",
    marginRight: "5%",
  },
  inputContainer: {},
  input: {
    marginTop: "5%",
    width: "100%",
    backgroundColor: THEME.secondary_color,
    borderRadius: 5,
  },
  dimensionSep: {
    fontWeight: "bolder",
    color: "gray",
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "6%",
  },
  dimensionInput: {
    marginTop: "5%",
    backgroundColor: THEME.secondary_color,
    borderRadius: 5,
  },
  dimensionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
};
