import { Paper } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

export default function ImageUpload({
  imageRefError,
  imageRef,
  handleImageSelection,
}) {
  return (
    <div style={style.container}>
      <label htmlFor="image-file-upload">
        <Paper
          id="SetUp"
          className={
            !imageRefError
              ? "custom-file-upload"
              : "custom-file-upload custom-file-upload-error"
          }
        >
          <AddPhotoAlternateIcon fontSize="large" />
          <p className={"uploadImageText"}>Upload Image</p>
        </Paper>
      </label>
      {imageRef !== null ? (
        <div style={style.imageContainer}>
          <img src={imageRef.uri} style={style.image} alt="preview" />
        </div>
      ) : null}
      <input
        id="image-file-upload"
        type="file"
        onChange={(event) => handleImageSelection(event.target.files[0])}
      />
    </div>
  );
}

const style = {
  container: {
    padding: "1%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5%",
  },
  imageContainer: {
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    marginTop: "5%",
  },
  image: {
    width: "200px",
    height: "200px",
    objectFit: "contain",
  },
};
