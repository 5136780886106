import * as React from "react";
import { useNavigate } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export default function SelectView({ editor }) {
  const navigate = useNavigate();

  const handleChange = (event, newEditor) => {
    if (newEditor !== null) {
      switch (newEditor) {
        case "artist":
          navigate("/artist-dashboard");
          break;
        case "art":
          navigate("/dashboard");
          break;
        case "live":
          navigate("/live");
          break;
        default:
          break;
      }
    }
  };

  return (
    <div style={style.container}>
      <StyledToggleButtonGroup
        value={editor}
        exclusive
        onChange={handleChange}
        aria-label="Editor"
      >
        <ToggleButton value="artist">Artist Editor</ToggleButton>
        <ToggleButton value="art">Art Editor</ToggleButton>
        <ToggleButton value="live">Live View</ToggleButton>
      </StyledToggleButtonGroup>
    </div>
  );
}

const style = {
  container: {
    flexGrow: 1,
    marginRight: "33%",
    marginLeft: "33%",
    display: "flex",
  },
};
