import { S3Client } from "@aws-sdk/client-s3";
import { Amplify, Auth } from "aws-amplify";
import { PRODUCTION } from "./constants";

export const client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  },
});

export const currentConfig = Auth.configure();

export const bucket = "auctios-image-store";

export const root = PRODUCTION
  ? `https://gcdhtchrt9.execute-api.us-east-2.amazonaws.com`
  : "http://localhost:3000";
