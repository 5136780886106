import QRCode from "react-qr-code";

const ArtistQR = ({ data }) => {
  const width = 524;
  const height = 250;
  const size = 175;
  const y_offset = height / 2 - size / 2;
  const x_offset = width * 0.45;

  const trimString = (cutoff, str, numParts) => {
    const words = str.split(" ");
    let strings = new Array(numParts).fill("");
    let index = 0;

    for (const word of words) {
      if (strings[index].length + word.length + 1 <= cutoff) {
        strings[index] += word + " ";
      } else if (index < numParts - 1) {
        index++;
        strings[index] = word + " ";
      } else {
        break;
      }
    }

    strings = strings.map((s) => s.trim());
    let totalLength = strings.reduce((sum, s) => sum + s.length, 0);
    strings[numParts - 1] += str.slice(totalLength + numParts - 1).trim();

    return strings;
  };

  const Medium = ({ y }) => {
    let str_arr = trimString(35, data.medium, 5);
    let spacing = 22.5;
    let vert = y;

    return (
      <g>
        {str_arr.map((str, index) => (
          <text
            key={index}
            x={x_offset}
            y={y_offset + vert + spacing * index}
            style={style.text}
          >
            {str}
          </text>
        ))}
      </g>
    );
  };

  return (
    <svg
      width={width}
      height={height}
      style={{
        backgroundColor: "white",
        display: "none",
      }}
      id={data.id}
    >
      <rect
        x={10}
        y={10}
        width={width - 20}
        height={height - 20}
        fill="white"
      />
      <g transform={`translate(${0} ${0})`}>
        <g transform={`translate(${10} ${y_offset})`}>
          <QRCode level="Q" value={data.link} size={size} />
        </g>
        <g className="text-group" transform={`translate(${-20} ${40})`}>
          <text x={x_offset} y={y_offset} style={style.header}>
            {data.full_name}
          </text>
          <Medium y={30} />
        </g>
      </g>
    </svg>
  );
};

const text = {
  fontSize: "1em",
  fontFamily: "Helvetica",
};

const style = {
  header: {
    ...text,
    fontWeight: "bold",
    fontSize: "1.5em",
  },
  text: {
    ...text,
    fontSize: "1.1em",
    fontWeight: "lighter",
  },
};

export default ArtistQR;
