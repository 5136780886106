import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { newAuction, addEventToAdmin, uploadToAws } from "../util/api";
import { AdminContext, getCacheByPath } from "../util/context";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { THEME } from "../util/theme";
import { LogoUpload } from "./LogoUpload";

export const NewEventPopup = ({ open, setOpen }) => {
  const [state, dispatch] = useContext(AdminContext);
  const [eventID, handleEventID] = useState("");
  const [startTime, handleStartTime] = useState(null);
  const [endTime, handleEndTime] = useState(null);
  const [imageRef, handleImageRef] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function setupHelper() {
    setLoading(true);
    try {
      if (validate()) {
        await newAuction(eventID, startTime, endTime, imageRef);
        await addEventToAdmin(state.id, {
          eventID: eventID,
        });
        if (imageRef !== null) await uploadToAws(imageRef);
        let events = state.events;
        events.push({ S: eventID });

        dispatch({
          type: "EVENT",
          payload: {
            eventID: eventID,
            id: state.id,
            events: events,
          },
        });
        navigate("/artist-dashboard");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  /** ensures the form is valid before submitting */
  async function validate() {
    if (eventID === "") {
      return false;
    }
    if (state.id === undefined) {
      const cachedAdmin = await getCacheByPath("admin");
      if (cachedAdmin !== null) {
        state.id = cachedAdmin.adminID.S;
      } else {
        // Handle the case when the id is not found in the cache
        console.error("ID not found in cache");
        return false;
      }
    }
    return true;
  }

  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <div style={style.container}>
        <IconButton
          style={style.button}
          aria-label="delete"
          onClick={() => setOpen(false)}
        >
          <CloseRoundedIcon style={style.icon} />
        </IconButton>
        <div style={style.infoContainer}>
          <p style={style.infoText}>
            Start by setting up a new event here. Once you create an event name,
            you can not change it without deleting the entire event.
          </p>
          <p style={style.infoText}>
            You can edit the event times at any point.
          </p>
          <p style={style.infoText}>
            Choose a logo that will be displayed in the bidding application at
            the time of the event.
          </p>
          <p style={style.infoText}>
            If you do not choose a logo, the default Auctios logo will be used
            instead.
          </p>
        </div>
        <form style={style.inputContainer} noValidate autoComplete="off">
          <div style={style.topInput}>
            <TextField
              sx={{ width: "100%" }}
              label="Event Name"
              variant="outlined"
              onChange={(event) => handleEventID(event.target.value)}
              style={style.input}
              value={eventID}
              required={true}
            />
          </div>
          <div style={style.input}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Start Time"
                value={startTime}
                onChange={handleStartTime}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div style={style.input}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="End Time"
                value={endTime}
                onChange={handleEndTime}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div style={style.input}>
            <LogoUpload imageRef={imageRef} handleImageRef={handleImageRef} />
          </div>
          <div style={style.input}>
            {loading ? (
              <Button
                variant={"outlined"}
                color={"inherit"}
                sx={style.createButton}
                disabled
              >
                <CircularProgress style={{ color: "black" }} size={20} />
              </Button>
            ) : (
              <Button
                variant={"outlined"}
                color={"inherit"}
                onClick={() => setupHelper()}
                sx={style.createButton}
              >
                Create
              </Button>
            )}
          </div>
        </form>
      </div>
    </Backdrop>
  );
};

const style = {
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    backgroundColor: "#fff",
    borderRadius: 20,
    paddingBottom: "2%",
    position: "relative",
    width: "50%",
    padding: "5%",
  },
  createButton: { width: "100%", padding: "5%" },
  infoContainer: {
    backgroundColor: "#f6f6f6",
    flex: 1,
    marginRight: "2.5%",
    padding: "1%",
    justifyContent: "center",
  },
  inputContainer: {
    flex: 1,
    marginLeft: "2.5%",
  },
  infoText: {
    textAlign: "left",
    paddingLeft: "5%",
  },
  topInput: {
    marginTop: "5%",
  },
  input: {
    marginBottom: "5%",
  },
  button: {
    padding: 0,
    position: "absolute",
    left: 10,
    top: 10,
  },
  icon: {
    fontSize: "1.25em",
    color: `${THEME.primary_color}`,
  },
};
