import { THEME } from "../../util/theme";
import React from "react";

export default function SideBar({
  Component,
  setPanelOpen,
  handleReload,
  mode,
}) {
  return (
    <div style={style.container}>
      <h1 style={style.header}>
        {mode === "art" ? "Add new piece" : "Add new artist"}
      </h1>
      <Component setPanelOpen={setPanelOpen} handleReload={handleReload} />
    </div>
  );
}

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  header: {
    fontSize: "2em",
    color: THEME.secondary_color,
    textAlign: "center",
  },
};
