import logo from "../images/new_logo_black.svg";

export default function UserLogo() {
  return (
    <div style={style.container}>
      <img src={logo} width={"50%"} alt={"logo"} />
    </div>
  );
}

const style = {
  container: {
    width: 80,
  },
};
