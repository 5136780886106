import React, { useState, useContext } from "react";
import { Button, TextField, Paper, CircularProgress } from "@mui/material";
import "../App.css";
import { login } from "../util/auth";
import { useNavigate } from "react-router-dom";
import { getAdmin } from "../util/api";
import { AdminContext, addCacheByPath } from "../util/context";
import logo from "../images/new_logo_black.svg";
import { VERSION } from "../util/constants";

export default function Login() {
  const [email, handleEmail] = useState("");
  const [password, handlePassword] = useState("");
  const [, dispatch] = useContext(AdminContext);
  const [authLoading, handleAuthLoading] = useState(false);
  const navigate = useNavigate();

  /** loads in the user to the local state via email and navigates to the
   *  to the dashboard if the eventID is not empty; otherwise navigates to the
   *  setup component.
   */
  async function signIn(email) {
    const admin = await getAdmin(email);
    dispatch({
      type: "LOGIN",
      payload: {
        email: admin.data.Item.email.S,
        name: admin.data.Item.name.S,
        id: admin.data.Item.adminID.S,
        eventID: admin.data.Item.eventID.S,
        events: admin.data.Item.events.L,
        stripeAccount: admin.data.Item.stripeAccount.S,
      },
    });
    addCacheByPath("admin", admin.data.Item);
    navigate("/events");
  }

  async function loginHelper() {
    handleAuthLoading(true);
    let { auth, user } = await login(email, password);
    if (auth) {
      signIn(email);
    } else if (user) {
      handleAuthLoading(false);
      navigate("/new-user", {
        state: {
          username: email,
          password: password,
        },
      });
    } else {
      handleAuthLoading(false);
      alert("Invalid login. Please try again.");
    }
  }

  return (
    <div className="App">
      <form noValidate autoComplete="off">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img src={logo} width={80} alt={"logo"} />
          <h1
            style={{
              fontSize: "5em",
              fontFamily: "Gill Sans",
              marginLeft: -5,
            }}
          >
            uctios
          </h1>
        </div>

        <div>
          <div className="input">
            <TextField
              id="email-input"
              label="Email"
              variant="outlined"
              onChange={(event) => handleEmail(event.target.value)}
              className="input"
              style={styles.input}
            />
          </div>
          <div className="input">
            <TextField
              id="password-input"
              label="Password"
              variant="outlined"
              onChange={(event) => handlePassword(event.target.value)}
              type="password"
              className="input"
              style={styles.input}
            />
          </div>
        </div>
        <div className="input">
          {authLoading ? (
            <Button sx={styles.loginButton} disabled>
              <CircularProgress
                style={{ color: "black", padding: 5 }}
                size={30}
              />
            </Button>
          ) : (
            <Button onClick={loginHelper}>
              <Paper id="SignIn" style={styles.loginButton}>
                <p id="SignInContent">LOGIN</p>
              </Paper>
            </Button>
          )}
        </div>
        <h6 className="footer">{`v${VERSION}`}</h6>
      </form>
    </div>
  );
}

const styles = {
  input: {
    width: 250,
  },
  loginButton: {
    backgroundColor: "#fff",
    border: "1px solid black",
    width: 250,
  },
};
