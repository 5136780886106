import QRCode from "react-qr-code";

const Background = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 975 431"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M380.5 5H32.5C17.3122 5 5 17.3122 5 32.5V395.453C5 412.04 18.4599 425.479 35.0466 425.453L940.047 424.047C956.597 424.021 970 410.597 970 394.047V315C970 298.431 956.569 285 940 285H454C437.431 285 424 271.569 424 255V35C424 18.4315 410.569 5 394 5H380.5Z"
        fill="url(#paint0_linear_757_2007)"
        stroke="white"
        stroke-width="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_757_2007"
          x1="5.00003"
          y1="26.9999"
          x2="970"
          y2="404"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2D45F6" />
          <stop offset="1" stop-color="#F75514" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const truncate = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

const QRCodeSVGColor = ({ data }) => {
  const width = 524;
  const height = 250;
  const size = 175;
  const y_offset = height / 2 - size / 2;
  const x_offset = width / 2 - 20;
  return (
    <svg
      width={width}
      height={height}
      style={{
        backgroundColor: "white",
        display: "none",
      }}
      id={data.id}
    >
      <Background />
      <g transform={`translate(${-5} ${0})`}>
        <rect
          x={x_offset - 5}
          y={y_offset / 2 - 12}
          width={width - y_offset + 1}
          height={(height - y_offset) * (3 / 4)}
          fill="white"
          rx={10}
        />
        <g transform={`translate(${y_offset - 2.5} ${y_offset})`}>
          <rect
            width={size * 1.1}
            height={size * 1.1}
            x={-10}
            y={-10}
            fill={"white"}
          />
          <QRCode value={data.link} size={size} />
        </g>

        <g className="text-group">
          <text x={x_offset} y={y_offset + 15} style={style.header}>
            {truncate(data.title, 24)}
          </text>
          <text x={x_offset} y={y_offset + 40} style={style.subheader}>
            {data.artist_name}
          </text>
          <text x={x_offset} y={y_offset + 75} style={style.text}>
            {data.year}
          </text>
          <text x={x_offset} y={y_offset + 95} style={style.text}>
            {data.medium}
          </text>
          <text x={x_offset} y={y_offset + 115} style={style.text}>
            {data.dimension}
          </text>
          <text x={x_offset} y={y_offset + 160} style={style.tagline}>
            Scan the QR code for live
          </text>
          <text x={x_offset} y={y_offset + 180} style={style.tagline}>
            auction and for more info!
          </text>
        </g>
      </g>
    </svg>
  );
};

const text = {
  fontSize: "1em",
  fontFamily: "Optima",
};

const style = {
  header: {
    ...text,
    fontWeight: "bold",
    fontSize: "1.5em",
  },
  subheader: {
    ...text,
    fontSize: "1.25em",
  },
  tagline: {
    ...text,
    fontSize: "1.25em",
    fill: "white",
    fontWeight: "bold",
  },
  text: {
    ...text,
    fontSize: ".9em",
  },
  line: {
    stroke: "black",
    strokeWidth: "3px",
  },
  label: {
    ...text,
    fontWeight: "bold",
    fontSize: ".75em",
    textAnchor: "middle",
  },
  rect: {
    fill: "none",
    stroke: "black",
    strokeWidth: "3px",
  },
};

export default QRCodeSVGColor;
