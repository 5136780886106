import axios from "axios";
import { bucket, root, client } from "./aws";
import { DeleteObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";

import imageCompression from "browser-image-compression";

const config = {
  headers: {
    Authorization: process.env.REACT_APP_API_KEY,
  },
};

export const getAuction = (eventID) => {
  return axios.get(root + "/auction/" + eventID, config);
};

export const getAdmin = (email) => {
  return axios.get(root + "/admin/" + email, config);
};

export const newAdmin = (email, name) => {
  const data = {
    eventID: "",
    events: [],
    name: name,
    email: email,
  };
  return axios.post(root + "/admin", data, config);
};

export const getArt = async (eventID) => {
  return axios
    .get(root + "/art/auction/" + eventID, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("failed to get art");
      return null;
    });
};

export const getArtByArtistID = async (artistID) => {
  return axios
    .get(root + "/art/artist/" + artistID, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("failed to get art");
      return null;
    });
};

export const getArtist = async (eventID) => {
  return await axios
    .get(root + "/artist/event/" + eventID, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("failed to get artist");
      return null;
    });
};

export const getStripeAccount = async (stripeID) => {
  return await axios
    .get(root + "/payment/account/" + stripeID, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("failed to get stripe account");
      return null;
    });
};

export const getBidder = async (bidID) => {
  return axios
    .get(root + "/bids/" + bidID, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("failed to get bidder");
      return null;
    });
};

export const getUser = async (userID) => {
  return axios
    .get(root + "/user/" + userID, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("failed to get user");
      return null;
    });
};

export const getArtistByID = async (artistID) => {
  return axios
    .get(root + "/artist/" + artistID, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("failed to get artist");
      return null;
    });
};

export const newAuction = (eventID, startTime, endTime, imageRef) => {
  const data = {
    eventID: eventID,
    start_time: startTime === null ? "" : startTime,
    end_time: endTime === null ? "" : endTime,
    active: true,
    image_ref: imageRef === null ? "" : imageRef.name,
  };
  return axios.post(root + "/auction", data, config);
};

export const updateAdmin = (adminID, data) => {
  return axios.put(root + "/admin/" + adminID, data, config);
};

export const addEventToAdmin = (adminID, data) => {
  return axios.put(root + "/admin/events/" + adminID, data, config);
};

export const updateAuction = (auctionID, data) => {
  return axios.put(root + "/auction/" + auctionID, data, config);
};

export const captureBid = (bidID) => {
  return axios.put(root + "/bids/capture/" + bidID, null, config);
};

export const updateArt = (artID, data) => {
  return axios.put(root + "/art/" + artID, data, config);
};

export const updateArtist = (artistID, data) => {
  return axios.put(root + "/artist/" + artistID, data, config);
};

export const newArt = (data) => {
  console.log(data);
  return axios
    .post(root + "/art", data, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("failed to make new art");
      return null;
    });
};

export const deleteAuction = (auctionID) => {
  axios.delete(root + "/auction/" + auctionID, config);
};

export const generateStripeLink = async (id) => {
  return await axios.get(root + "/payment/link/" + id, config);
};

export const capturePayment = async (data) => {
  let success = false;
  await axios
    .post(root + "/payment", data, config)
    .then((res) => {
      console.log(res);
      success = true;
    })
    .catch((err) => {
      console.log(err);
    });
  return success;
};

function convertUriToName(uri) {
  let l_index = uri.indexOf("/images");
  let r_index = uri.indexOf("?");
  return uri.substring(l_index + 10, r_index);
}

export const deleteArt = async (artID, uri) => {
  const imageName = convertUriToName(uri);
  await axios.delete(root + "/art/" + artID, config);
  return deleteImage("images/" + imageName).then(async () => {
    await deleteImage("thumbnails/" + imageName);
  });
};

export const deleteArtist = async (artistID, uri) => {
  const imageName = convertUriToName(uri);
  await axios.delete(root + "/artist/" + artistID, config);
  return deleteImage("images/" + imageName).then(async () => {
    await deleteImage("thumbnails/" + imageName);
  });
};

export const newArtist = async (data) => {
  return await axios
    .post(root + "/artist", data, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("failed to make new artist");
      return null;
    });
};

/** @param imageName { image_name.extension } */
async function deleteImage(imageName) {
  try {
    const params = {
      Bucket: bucket,
      Key: imageName,
    };
    const data = await client.send(new DeleteObjectCommand(params));
    console.log(
      "Successfully deleted object: " + params.Bucket + "/" + params.Key
    );
    return data;
  } catch (err) {
    console.log("Error", err);
  }
}

/** @param blob { blob } */
async function _processThumbnail(blob) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 500,
  };
  const thumbnail = await imageCompression(blob, options);
  return thumbnail;
}

/** @param image { obj { uri, name }} */
export async function uploadToAws(image) {
  try {
    const response = await fetch(image.uri);
    const blob = await response.blob();
    const imageName = image.name;

    // upload image
    const params = {
      Bucket: bucket,
      Key: "images/" + imageName,
      Body: blob,
    };
    const data = await client.send(new PutObjectCommand(params));
    console.log(
      "Successfully uploaded image: " + params.Bucket + "/" + params.Key
    );

    // upload thumbnail
    const tBlob = await _processThumbnail(blob);
    const tParams = {
      Bucket: bucket,
      Key: "thumbnails/" + imageName,
      Body: tBlob,
    };
    await client.send(new PutObjectCommand(tParams));
    console.log(
      "Successfully uploaded thumbnail: " + params.Bucket + "/" + params.Key
    );

    return data;
  } catch (err) {
    console.log("Error", err);
  }
}

export function getImageLink(reference) {
  return `https://auctios-image-store.s3.amazonaws.com/thumbnails/${reference}`;
}

/** converts a timestamp, representated as a map, to a stringified time. */
export function convertTimeToDate(_timestamp) {
  if (_timestamp === null || _timestamp === "") {
    return "";
  }
  let timestamp = new Date(_timestamp);
  let date = timestamp.toLocaleString();

  let findex_1 = date.lastIndexOf("/") + 1;
  let findex_2 = date.lastIndexOf(",");
  return date.substring(0, findex_1) + date.substring(findex_1 + 2, findex_2);
}
