import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LogoUpload } from "../LogoUpload";
import { THEME } from "../../util/theme";
import { getImageLink, updateAuction, uploadToAws } from "../../util/api";

export const EventEditorPopup = ({ open, setOpen, event }) => {
  const [startTime, handleStartTime] = useState(event.start_time.S);
  const [endTime, handleEndTime] = useState(event.end_time.S);
  const [imageRef, handleImageRef] = useState({
    uri: getImageLink(event.image_ref.S),
    name: event.image_ref.S.split("/").pop(),
    type: "image/png",
  });
  const [pickupMessage, handlePickupMessage] = useState(event.pickup_message.S);
  const [loading, setLoading] = useState(false);
  const [imageChanged, setImageChanged] = useState(false);

  async function editEventHelper() {
    setLoading(true);
    if (!validate()) {
      setLoading(false);
      return;
    }
    try {
      const data = {
        start_time: startTime,
        end_time: endTime,
        image_ref: imageRef.name,
        pickup_message: pickupMessage,
      };
      await updateAuction(event.auctionID.S, data);
      if (imageChanged) {
        await uploadToAws(imageRef);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  }

  function validate() {
    if (startTime !== null && endTime !== null && endTime > startTime) {
      return true;
    }
    return false;
  }

  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <div style={style.container}>
        <IconButton
          style={style.button}
          aria-label="delete"
          onClick={() => setOpen(false)}
        >
          <CloseRoundedIcon style={style.icon} />
        </IconButton>
        <div style={style.headerContainer}>
          <h3 style={style.title}>{event.eventID.S}</h3>
        </div>
        <div style={style.middleContainer}>
          <div style={style.leftContainer}>
            <div style={style.input}>
              <LogoUpload
                imageRef={imageRef}
                handleImageRef={(imageRef) => {
                  handleImageRef(imageRef);
                  setImageChanged(true);
                }}
                _preview_hidden={true}
                sx={style.iconButton}
              />
            </div>
            {imageRef && imageRef.name !== "" ? (
              <img src={imageRef.uri} style={style.image} alt="preview" />
            ) : (
              <div style={style.placeholderImage}></div>
            )}
          </div>
          <div style={style.rightContainer}>
            <div style={style.dateContainer}>
              <div style={{ ...style.input, marginRight: "1%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Start Time"
                    value={startTime}
                    onChange={handleStartTime}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ ...style.input, marginLeft: "1%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="End Time"
                    value={endTime}
                    onChange={handleEndTime}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div style={style.messageContainer}>
              <TextField
                label="Art Pickup Message"
                variant="outlined"
                multiline
                rows={8}
                onChange={(event) => handlePickupMessage(event.target.value)}
                style={style.messageInput}
                defaultValue={pickupMessage}
              />
            </div>
          </div>
        </div>
        <div style={style.footerContainer}>
          <div style={style.input}>
            {loading ? (
              <Button
                variant={"outlined"}
                color={"inherit"}
                disabled
                sx={style.saveButton}
              >
                <CircularProgress style={{ color: "black" }} size={20} />
              </Button>
            ) : (
              <Button
                variant={"outlined"}
                color={"inherit"}
                onClick={() => editEventHelper()}
                sx={style.saveButton}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

const style = {
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: 20,
    paddingBottom: "2%",
    position: "relative",
    width: "50%",
    padding: "5%",
  },
  saveButton: {
    width: 200,
    height: 50,
    top: 25,
  },
  title: {
    textAlign: "center",
    color: THEME.primary_color,
    fontSize: "2em",
    fontWeight: "bold",
    marginTop: "-5%",
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  middleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    border: "1px solid #c4c4c4",
    borderRadius: 5,
    justifyContent: "center",
  },
  iconButton: {
    container: {
      alignItems: "center",
      justifyContent: "center",
      marginRight: "5%",
      marginLeft: "5%",
    },
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    width: "67%",
  },
  dateContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  messageContainer: {
    flex: 3,
  },
  footerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  input: {
    marginBottom: "5%",
  },
  messageInput: {
    marginBottom: "5%",
    alignSelf: "center",
    width: "100%",
    height: "auto",
    maxHeight: "200px",
  },
  button: {
    padding: 0,
    position: "absolute",
    left: 20,
    top: 20,
  },
  icon: {
    fontSize: "1.25em",
    color: `${THEME.primary_color}`,
  },
  image: {
    width: "80%",
    height: "auto",
    alignSelf: "center",
  },
  placeholderImage: {
    width: 125,
    height: 125,
    backgroundColor: "#e4e4e4",
    alignSelf: "center",
  },
};
