import { CircularProgress } from "@mui/material";
import { THEME } from "../../util/theme";

export default function Loading({ text }) {
  return (
    <div style={style.container}>
      <h4 style={style.text}>{text}</h4>
      <CircularProgress style={{ color: "white" }} />
    </div>
  );
}

const style = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "50%",
    marginBottom: "50%",
  },
  text: {
    color: THEME.secondary_color,
    textAlign: "center",
    marginBottom: "25%",
  },
};
