import React, { useState, useContext } from "react";
import { Button, TextField, Paper, CircularProgress } from "@mui/material";
import "../App.css";
import { loginNew } from "../util/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { getAdmin } from "../util/api";
import { AdminContext } from "../util/context";
import logo from "../images/new_logo_black.svg";
import { VERSION } from "../util/constants";

export default function LoginFirstTime() {
  const [password, handlePassword] = useState("");
  const [name, handleName] = useState("");

  const [, dispatch] = useContext(AdminContext);
  const [loading, handleLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  async function signIn(email) {
    const admin = await getAdmin(email);
    dispatch({
      type: "LOGIN",
      payload: {
        email: admin.data.Item.email.S,
        name: admin.data.Item.name.S,
        id: admin.data.Item.adminID.S,
        eventID: admin.data.Item.eventID.S,
        events: admin.data.Item.events.L,
        stripeAccount: admin.data.Item.stripeAccount.S,
      },
    });
    navigate("/events");
  }

  async function loginHelper() {
    handleLoading(true);
    if (name === "" || password === "") {
      handleLoading(false);
      return;
    }
    const authenticated = await loginNew(
      name,
      location.state.username,
      location.state.password,
      password
    );
    if (authenticated) {
      signIn(location.state.username);
    } else {
      handleLoading(false);
      alert("Invalid login. Please try again.");
    }
  }

  return (
    <div className="App">
      <form noValidate autoComplete="off">
        {loading ? (
          <CircularProgress color="inherit" />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img src={logo} width={60} alt={"logo"} />
            <h1
              style={{
                fontSize: "2.5em",
                fontFamily: "Gill Sans",
                marginLeft: -5,
              }}
            >
              uctios
            </h1>
          </div>
        )}
        <div className="input">
          <TextField
            id="name-input"
            label="Full Name"
            variant="outlined"
            onChange={(event) => handleName(event.target.value)}
            className="input"
          />
        </div>
        <div className="input">
          <TextField
            id="password-input"
            label="*New Password"
            variant="outlined"
            onChange={(event) => handlePassword(event.target.value)}
            type="password"
            className="input"
          />
          <p>Must reset password on first login</p>
        </div>
        <div className="input">
          <Button onClick={loginHelper}>
            <Paper
              id="SignIn"
              style={{
                backgroundColor: "#fff",
                width: 190,
                border: "1px solid black",
              }}
            >
              <p id="SignInContent">LOGIN</p>
            </Paper>
          </Button>
        </div>
        <h6 className="footer">{`v${VERSION}`}</h6>
      </form>
    </div>
  );
}
