import QRCode from "react-qr-code";

const QRCodeSVG = ({ data }) => {
  const width = 524;
  const height = 250;
  const size = 175;
  const y_offset = height / 2 - size / 2;
  const x_offset = width * 0.45;

  const trimString = (cutoff, str) => {
    const words = str.split(" ");
    let firstString = "";
    for (const word of words) {
      if (firstString.length + word.length + 1 <= cutoff) {
        firstString += word + " ";
      } else {
        break;
      }
    }
    firstString = firstString.trim();
    const secondString = str.slice(firstString.length).trim();
    return [firstString, secondString];
  };

  const convertDimension = (dimensions) => {
    const x = dimensions.indexOf("x");
    const width = dimensions.substring(0, x);
    const height = dimensions.substring(x + 1);
    return [width, height];
  };

  const Dimension = ({ y }) => {
    const dimensions = convertDimension(data.dimension);
    return (
      <text x={x_offset} y={y_offset + y} style={style.text}>
        {data.dimension === "0x0"
          ? null
          : `${dimensions[0]}" x ${dimensions[1]}"`}
      </text>
    );
  };

  const Medium = ({ y }) => {
    let str_arr = trimString(37, data.medium);
    let spacing = 22.5;
    let vert = y;

    return data.medium.length < 37 ? (
      <g>
        <text x={x_offset} y={y_offset + vert} style={style.text}>
          {data.year}
        </text>
        <text x={x_offset} y={y_offset + vert + spacing} style={style.text}>
          {data.medium}
        </text>
        <Dimension y={vert + 2 * spacing} />
      </g>
    ) : (
      <g>
        <text x={x_offset} y={y_offset + vert} style={style.text}>
          {data.year}
        </text>
        <text x={x_offset} y={y_offset + vert + spacing} style={style.text}>
          {str_arr[0]}
        </text>
        <text x={x_offset} y={y_offset + vert + 2 * spacing} style={style.text}>
          {str_arr[1]}
        </text>
        <Dimension y={vert + 3 * spacing} />
      </g>
    );
  };

  const Title = ({ y }) => {
    let str_arr = trimString(27, data.title);
    return data.title.length < 27 ? (
      <g>
        <text x={x_offset} y={y_offset + y} style={style.header}>
          {data.artist_name}
        </text>
        <text x={x_offset} y={y_offset + y + 50} style={style.subheader}>
          {data.title}
        </text>
      </g>
    ) : (
      <g>
        <text x={x_offset} y={y_offset + y} style={style.header}>
          {data.artist_name}
        </text>
        <text x={x_offset} y={y_offset + y + 50} style={style.subheader}>
          {str_arr[0]}
        </text>
        <text x={x_offset} y={y_offset + y + 75} style={style.subheader}>
          {str_arr[1]}
        </text>
      </g>
    );
  };

  const TextGroup = () => {
    let title_offset = 20;
    let medium_offset = data.title.length < 27 ? 95 : 120;
    return (
      <g>
        <Title y={title_offset} />
        <Medium y={medium_offset} />
      </g>
    );
  };

  return (
    <svg
      width={width}
      height={height}
      style={{
        backgroundColor: "white",
        display: "none",
      }}
      id={data.id}
    >
      <rect
        x={10}
        y={10}
        width={width - 20}
        height={height - 20}
        fill="white"
      />
      <g transform={`translate(${0} ${0})`}>
        <g transform={`translate(${10} ${y_offset})`}>
          <QRCode level="Q" value={data.link} size={size} />
        </g>
        <g
          className="text-group"
          transform={`translate(${-20} ${
            data.title.length < 27 && data.medium.length < 37
              ? 15
              : data.title.length >= 27 && data.medium.length < 37
              ? 5
              : -5
          })`}
        >
          <TextGroup />
        </g>
      </g>
    </svg>
  );
};

const text = {
  fontSize: "1em",
  fontFamily: "Helvetica",
};

const style = {
  container: {
    stroke: "black",
    strokeWidth: 2,
  },
  header: {
    ...text,
    fontWeight: "bold",
    fontSize: "1.5em",
  },
  subheader: {
    ...text,
    fontWeight: "bold",
    fontSize: "1.35em",
    fontStyle: "italic",
  },
  text: {
    ...text,
    fontSize: "1.1em",
    fontWeight: "lighter",
  },
  line: {
    stroke: "black",
    strokeWidth: "3px",
  },
  label: {
    ...text,
    fontWeight: "bold",
    fontSize: ".75em",
    textAnchor: "middle",
  },
  rect: {
    fill: "none",
    stroke: "black",
    strokeWidth: "3px",
  },
};

export default QRCodeSVG;
